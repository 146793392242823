import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebase"; // Import your Firebase configuration
import { useQuery } from "@tanstack/react-query";

export default function useTrainers() {
  const func = async () => {
    try {
      const dataRef = collection(db, "physiotherapist");
      const querySnapshot = await getDocs(dataRef);

      const fetchData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // setData(fetchData);
      return fetchData;
    } catch (error) {
      console.error(`Error fetching data`, error.message);
    }
  };

  const { data: trainers } = useQuery({
    queryKey: ["trainers"],
    queryFn: func,
  });

  return trainers;
}
