import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import App from "./App";
import ReactGA from "react-ga";
import { ChakraProvider } from "@chakra-ui/react";
import { AuthProvider } from "./context/authContext";
// require('dotenv').config();
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

ReactGA.initialize('G-3GRXXMSKR3');
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <ChakraProvider>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </ChakraProvider>
    </AuthProvider>
  </React.StrictMode>
);
