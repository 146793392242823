// import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebase"; // Import your Firebase configuration
import { useQuery } from "@tanstack/react-query";

const useUsers = () => {
  // const [data, setData] = useState([]);

  const func = async () => {
    try {
      const dataRef = collection(db, "Users");
      const querySnapshot = await getDocs(dataRef);

      const fetchData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      // setData(fetchData);
      return fetchData;
    } catch (error) {
      console.error(`Error fetching data `, error);
    }
  };
  // useEffect(() => {
  //   func();
  // }, []);
  const { data: users } = useQuery({
    queryKey: ["users"],
    queryFn: func,
  });

  return users;
};

export default useUsers;
