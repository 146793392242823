import React, { useState, useEffect } from "react";
import DataFetcher from "../components/data_fetch/datafetcher";
import ClientChart from "../charts/clientChart";
import TrainerChart from "../charts/trainerChart";
import classes from "./AdminDashboard.module.scss";
import {
  Center,
  CircularProgress,
  CircularProgressLabel,
  Text,
} from "@chakra-ui/react";
import useDate from "../../components/data_fetch/useDate";
import useExercises from "../components/data_fetch/useExercises";
import useTrainers from "../components/data_fetch/useTrainers";
import useUsers from "../components/data_fetch/useUsers";
const AdminDashboard = ({ isMobile }) => {
  const date = useDate();
  const [exerciseCount, setExerciseCount] = useState(0);
  const [clientCount, setClientCount] = useState(0);
  const [physiotherapistCount, setPhysiotherapistCount] = useState(0);

  // Store fetched data in state
  // const exercisesData = useDataFetch("exercises");
  // const usersData = useDataFetch("Users");
  // const physiotherapistData = useDataFetch("physiotherapist");
  const usersData = useUsers();
  const physiotherapistData = useTrainers();
  const exercisesData = useExercises();
  const [selectedYearClient, setSelectedYearClient] = useState(
    new Date().getFullYear()
  );
  const [selectedYearTrainer, setSelectedYearTrainer] = useState(
    new Date().getFullYear()
  );

  // Store client creation count by month
  const [clientCreationByMonth, setClientCreationByMonth] = useState(
    new Array(12).fill(0)
  );
  // Store physiotherapist creation count by month
  const [physiotherapistCreationByMonth, setPhysiotherapistCreationByMonth] =
    useState(new Array(12).fill(0));

  useEffect(() => {
    exercisesData && setExerciseCount(exercisesData.length);
  }, [exercisesData]);

  useEffect(() => {
    if (usersData) {
      setClientCount(usersData.length);

      // Calculate client creation count by month
      const creationCounts = new Array(12).fill(0);
      usersData.forEach((user) => {
        const createdDate = new Date(user.accCreated);
        if (createdDate.getFullYear() == selectedYearClient) {
          const month = createdDate.getMonth();
          creationCounts[month]++;
        }
      });
      setClientCreationByMonth(creationCounts);
    }
  }, [selectedYearClient, usersData]);

  useEffect(() => {
    if (physiotherapistData) {
      setPhysiotherapistCount(physiotherapistData.length);
      // Calculate physiotherapist creation count by month
      const creationCounts = new Array(12).fill(0);
      physiotherapistData.forEach((physiotherapist) => {
        const createdDate = new Date(physiotherapist.accCreated);
        if (createdDate.getFullYear() == selectedYearTrainer) {
          const month = createdDate.getMonth();
          creationCounts[month]++;
        }
      });
      setPhysiotherapistCreationByMonth(creationCounts);
    }
  }, [physiotherapistData, selectedYearTrainer]);

  const handleYearChangeClient = (year) => {
    setSelectedYearClient(year);
  };

  const handleYearChangeTrainer = (year) => {
    setSelectedYearTrainer(year);
  };
  return (
    <div className={classes.dashboard}>
      <div className={classes.header}>
        <p>Welcome Back Trai</p>
        <Center className={classes.date} fontWeight="500">
          {date}
        </Center>
      </div>
      <div className={classes.rootHealthData}>
        <div className={classes.graphs}>
          <div className={classes.circle}>
            <Center
              className={classes.pieChart}
              bgGradient="linear(to-br, #7fa5ed, #2a49b6)"
            >
              <CircularProgress
                value={clientCount ? clientCount : null}
                color="#88A8ED"
                thickness="6px"
              >
                <CircularProgressLabel>
                  <Text className={classes.data}>
                    {clientCount ? clientCount : "N/A"}
                  </Text>
                </CircularProgressLabel>
              </CircularProgress>
            </Center>
            <div className={classes.head}>
              <p>Clients</p>
            </div>
          </div>

          <div className={classes.circle}>
            <Center
              className={classes.pieChart}
              bgGradient="linear(to-br, #7fa5ed, #2a49b6)"
            >
              <CircularProgress
                value={physiotherapistCount ? physiotherapistCount : null}
                color="#88A8ED"
                thickness="6px"
              >
                <CircularProgressLabel>
                  <Text className={classes.data}>
                    {physiotherapistCount
                      ? parseFloat(physiotherapistCount).toFixed(0)
                      : "N/A"}
                  </Text>
                </CircularProgressLabel>
              </CircularProgress>
            </Center>
            <div className={classes.head}>
              <p>Trainers</p>
            </div>
          </div>

          <div className={classes.circle}>
            <Center
              className={classes.pieChart}
              bgGradient="linear(to-br, #7fa5ed, #2a49b6)"
            >
              <CircularProgress
                value={exerciseCount ? exerciseCount : null}
                color="#88A8ED"
                thickness="6px"
              >
                <CircularProgressLabel>
                  <Text className={classes.data}>
                    {exerciseCount ? exerciseCount : "N/A"}
                  </Text>
                </CircularProgressLabel>
              </CircularProgress>
            </Center>
            <div className={classes.head}>
              <p>Exercises</p>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.clientChart}>
        <ClientChart
          onYearChange={handleYearChangeClient}
          months={getMonthNames()}
          physiotherapistCounts={clientCreationByMonth}
          isMobile={isMobile}
        />
      </div>
      <div className={classes.physioChart}>
        <TrainerChart
          onYearChange={handleYearChangeTrainer}
          months={getMonthNames()}
          physiotherapistCounts={physiotherapistCreationByMonth}
          isMobile={isMobile}
        />
      </div>
    </div>
  );
};

function getMonthNames() {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return months.map((month) => month.substring(0, 3));
}

export default AdminDashboard;
