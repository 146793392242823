import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import "./Chart.scss";

const PhysiotherapistChart = ({
  months,
  physiotherapistCounts,
  isMobile,
  onYearChange,
}) => {
  const currentYear = new Date().getFullYear();
  const startYear = 2020; // Change this to the desired start year
  const [selectedYear, setSelectedYear] = useState(currentYear);

  useEffect(() => {
    onYearChange(selectedYear);
  }, [selectedYear, onYearChange]);

  const years = Array.from(
    { length: currentYear - startYear + 1 },
    (_, index) => startYear + index
  );

  const handleYearChange = (event) => {
    const year = parseInt(event.target.value, 10);
    setSelectedYear(year);
  };

  const series = [
    {
      name: "Clients",
      data: physiotherapistCounts,
    },
  ];

  // ApexCharts configuration
  const chartOptions = {
    chart: {
      type: "bar",
      height: "100%",
      weight: "100%",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: months,
      labels: {
        show: true, // This ensures that x-axis labels are visible
        style: {
          colors: "#456ACC",
          fontSize: "1rem",
        },
      },
    },
    yaxis: {
      labels: {
        offsetX: -18,
        style: {
          colors: "#4065C9",
          fontSize: "1rem",
        },
      },
      tickAmount: 5,
    },
    grid: {
      show: false,
      borderColor: "#AEA8FF",
      position: "back",
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    tooltip: {
      theme: "dark",
      x: {
        show: true,
      },
      y: {
        formatter: (value) => `${value} units`,
      },
      marker: {
        show: true,
      },
      style: {
        background: "#FF0000",
        color: "#FFFFFF",
      },
    },
    dataLabels: {
      enabled: false, // Hide data labels on bars
    },
    plotOptions: {
      bar: {
        colors: {
          ranges: [
            {
              from: 0,
              to: 100,
              color: "#6585D7",
            },
          ],
        },
      },
    },
  };

  return (
    <div className="chart">
      <div className="header">
        <p className="title">Trainers</p>

        <div className="button">
          <select
            id="yearDropdown"
            value={selectedYear}
            onChange={handleYearChange}
          >
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="chartMain">
        <ReactApexChart
          options={chartOptions}
          series={series}
          type="bar"
          height={chartOptions.chart.height}
          width={chartOptions.chart.width}
        />
      </div>
    </div>
  );
};

export default PhysiotherapistChart;
