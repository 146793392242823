import React, { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase"; // Import your Firebase configuration
import classes from "../Settings/Settings.module.scss";
import { motion } from "framer-motion";
import { Center, Spinner, useToast } from "@chakra-ui/react";
import useDate from "../../components/data_fetch/useDate";
import bcrypt from "bcryptjs";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
function AdminRegistration() {
  const date = useDate();
  const toast = useToast();
  const [currentpassword, setCurrentPassword] = useState();
  const [hashedPass, setHashedPass] = useState();
  const [adminInfo, setAdminInfo] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const hashPassword = async (password) => {
    try {
      const salt = await bcrypt.genSalt(10); // Generate a salt with 10 rounds
      const hash = await bcrypt.hash(password, salt); // Hash the password with the generated salt
      return hash;
    } catch (error) {
      console.error("Error hashing password:", error);
      throw error;
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAdminInfo({ ...adminInfo, [name]: value });
  };

  const handleCurrentPassword = (e) => {
    setCurrentPassword(e.target.value);
    hashPassword(e.target.value).then((pass) => {
      setHashedPass(pass);
    });
  };

  const handleRegistration = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Check if email and password fields are empty
      if (!adminInfo.email || !adminInfo.password) {
        throw new Error("Email and password must not be empty.");
      }

      // Add a new document to the "Admins" collection
      if (adminInfo.password == currentpassword) {
        const adminRef = collection(db, "Admins");
        await addDoc(adminRef, {
          name: adminInfo.name,
          email: adminInfo.email,
          password: hashedPass,
          profileImage: "",
        });
      } else {
        throw new Error("Password didnot match");
      }
      // console.log("Admin registered with ID: ", newAdminDoc.id);
      // Optionally, you can redirect the admin to the login page or admin panel.

      // Clear the form after successful registration
      setAdminInfo({
        name: "",
        email: "",
        password: "",
      });
      setCurrentPassword("");
      setLoading(false);
      toast({
        title: "Form Submitted",
        description: "Admin registered successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error!",
        description: `${error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  return (
    <div className={classes.settings}>
      <div className={classes.header}>
        <p>Admin Registration</p>
        <Center fontWeight="500" className={classes.date}>
          {date}
        </Center>
      </div>

      <div className={classes.container}>
        <form onSubmit={handleRegistration}>
          <div className={classes.field}>
            <label className={classes.fieldName}>Name</label>
            <input
              type="name"
              className={classes.fieldInput}
              name="name"
              value={adminInfo.name}
              onChange={handleInputChange}
            />
          </div>
          <div className={classes.field}>
            <label className={classes.fieldName}>Email</label>
            <input
              type="email"
              className={classes.fieldInput}
              name="email"
              value={adminInfo.email}
              onChange={handleInputChange}
            />
          </div>
          <div className={classes.field}>
            <label className={classes.fieldName}>Password</label>
            <div className={classes.fieldInput}>
              <input
                className={classes.Input}
                type={showPassword ? "text" : "password"}
                name="password"
                value={adminInfo.password}
                onChange={handleInputChange}
              />
              <Center className={classes.icon}>
                {showPassword ? (
                  <IoEyeOutline
                    // className={classes.EyeIcon}
                    onClick={() => setShowPassword(!showPassword)}
                  />
                ) : (
                  <IoEyeOffOutline
                    // className={classes.EyeIcon}
                    onClick={() => setShowPassword(!showPassword)}
                  />
                )}
              </Center>
            </div>
          </div>
          <div className={classes.field}>
            <label className={classes.fieldName}>Current Password</label>
            <div className={classes.fieldInput}>
              <input
                className={classes.Input}
                type={showCurrentPassword ? "text" : "password"}
                name="currentpassword"
                value={currentpassword}
                onChange={handleCurrentPassword}
              />
              <Center className={classes.icon}>
                {showCurrentPassword ? (
                  <IoEyeOutline
                    // className={classes.EyeIcon}
                    onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                  />
                ) : (
                  <IoEyeOffOutline
                    // className={classes.EyeIcon}
                    onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                  />
                )}
              </Center>
            </div>
          </div>
          {loading ? (
            <Center className={classes.button}>
              <Spinner color="white" />
            </Center>
          ) : (
            <motion.button
              whileTap={{ scale: 1.1 }}
              whileHover={{ scale: 0.98 }}
              type="submit"
              className={classes.button}
            >
              Register
            </motion.button>
          )}
        </form>
      </div>
    </div>
  );
}

export default AdminRegistration;
