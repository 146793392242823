import React from "react";
import styles from "../Clients/ClientsPage.module.scss";
import AdminTherapistEdit from "./adminTherapistEdit";
import useDate from "../../components/data_fetch/useDate";
import { Center, Spinner } from "@chakra-ui/react";
import useTrainers from "../components/data_fetch/useTrainers";

const AdminTherapistsPage = ({ onSelectTherapist }) => {
  const date = useDate();
  const therapists = useTrainers();

  return (
    <div className={styles.rootClients}>
      <div className={styles.header}>
        <div className={styles.title}>
          <p>Trainers</p>
        </div>
        <Center fontWeight="500" className={styles.date}>
          {date}
        </Center>
      </div>

      <div className={styles.container}>
        {typeof therapists === "undefined" ? (
          <Center mt="20%">
            <Spinner color="navy" size="xl" />
          </Center>
        ) : (
          <div className={styles.listContainer}>
            {therapists &&
              therapists.map((therapist) => (
                <div className={styles.card} key={therapist.userID}>
                  <div className={styles.imageContainer}>
                    {therapist.profileImageURL !== "" ? (
                      <img
                        src={therapist.profileImageURL}
                        alt={therapist.name}
                      />
                    ) : (
                      <img
                        src={require("../../assets/vectorProfile.png")}
                        alt=""
                      />
                    )}
                  </div>

                  <div className={styles.textContainer}>
                    <div className={styles.username}>
                      <p>{therapist.name}</p>
                    </div>

                    <div className={styles.viewbox}>
                      <div
                        className={styles.buttons}
                        onClick={() => onSelectTherapist(therapist)}
                      >
                        <p>View</p>
                      </div>
                      <AdminTherapistEdit props={therapist} />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminTherapistsPage;
