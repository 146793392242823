import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../firebase";
import AuthContext from "../../../context/authContext";

export default function useImageUrl() {
  const { user } = useContext(AuthContext);

  const func = async () => {
    try {
      const adminsRef = collection(db, "Admins");
      const q = query(adminsRef, where("email", "==", JSON.parse(user).email));
      const querySnapshot = await getDocs(q);
      const adminData = querySnapshot.docs[0].data();
      return adminData.profileImage;
    } catch (err) {
      console.log(err.message);
    }
  };

  const { data: imageUrl } = useQuery({
    queryKey: ["adminimage"],
    queryFn: func,
  });
  return imageUrl;
}
