import React, { useState } from "react";
// import "./ForgotPassword.css";
import classes from "./ForgotPassword.module.scss";
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { motion } from "framer-motion";
import { Button, Spinner, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import login from "../../assets/login.png";
import { MdEmail } from "react-icons/md";
import bcrypt from "bcryptjs";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  // const [errorMessage, setErrorMessage] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const toast = useToast();
  const Navigate = useNavigate();

  const hashPassword = async (password) => {
    try {
      const salt = await bcrypt.genSalt(10); // Generate a salt with 10 rounds
      const hash = await bcrypt.hash(password, salt); // Hash the password with the generated salt
      return hash;
    } catch (error) {
      console.error("Error hashing password:", error);
      throw error;
    }
  };
  const generateRandomPassword = () => {
    // You can customize this function to generate a random password as per your requirements
    const chars =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    const passwordLength = 8;
    let password = "";

    for (let i = 0; i < passwordLength; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      password += chars[randomIndex];
    }
    return password;
  };
  const handelCancel = () => {
    Navigate("/");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    try {
      if (email === "") {
        throw new Error("Email cannot be empty");
      }
      // Check if the authenticated admin exists in the "Admins" collection
      const adminsRef = collection(db, "Admins");
      const q = query(adminsRef, where("email", "==", email));
      const querySnapshot = await getDocs(q);

      const password = generateRandomPassword();
      const hashedPassword = await hashPassword(password);

      if (querySnapshot.empty) {
        throw new Error("Admin not found");
      } else {
        const adminDocRef = doc(db, "Admins", querySnapshot.docs[0].id);
        await updateDoc(
          adminDocRef,
          { password: hashedPassword },
          { merge: true }
        );
        fetch(
          "https://us-central1-physiotherapistadmin.cloudfunctions.net/sendMail",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email, password }),
          }
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            toast({
              title: data.message,
              description: `Password has been sent to ${email}`,
              status: "success",
              duration: 5000,
              isClosable: true,
            });
            setSubmitting(false);
            handelCancel();
          })
          .catch((error) => {
            toast({
              title: "Error!",
              description: `There was a problem sending the email`,
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            setSubmitting(false);
          });
      }
    } catch (error) {
      toast({
        title: "Error!",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setSubmitting(false);
    }
  };

  return (
    <div className={classes.rootLogin}>
      <div className={classes.containerMain}>
        <div className={classes.container}>
          <div className={classes.left}>
            <div className={classes.email}>
              <div className={classes.header}>
                <p>Forgot Password</p>
              </div>
              <div className={classes.label}>
                <p>Enter your registered email address.</p>
              </div>
              <div className={classes.input}>
                <div className={classes.icon}>
                  <MdEmail className={classes.emailIcon} />
                </div>

                <div className={classes.inputEmail}>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className={classes.buttons}>
              {submitting ? (
                <div className={classes.submit}>
                  <Spinner color="white" />
                </div>
              ) : (
                <motion.div
                  whileTap={{ scale: 0.85 }}
                  whileHover={{ scale: 1.05 }}
                  className={classes.submit}
                  onClick={handleSubmit}
                >
                  <p>Submit</p>
                </motion.div>
              )}
              <motion.div
                whileTap={{ scale: 0.85 }}
                whileHover={{ scale: 1.05 }}
                className={classes.cancel}
                onClick={handelCancel}
              >
                <p>Cancel</p>
              </motion.div>
            </div>
          </div>
          <div className={classes.right}>
            <img src={login} alt="login"></img>
          </div>
        </div>
      </div>
    </div>
  );
}
