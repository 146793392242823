import React, { useState } from "react";
import styles from "./ExercisesPage.module.scss";
import { HiOutlineViewGrid, HiOutlineViewList } from "react-icons/hi";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { Center, Spinner } from "@chakra-ui/react";
import useDate from "../../components/data_fetch/useDate";
import useExercises from "../components/data_fetch/useExercises";

const AdminExercises = ({
  onAddExercisesClick,
  onExerciseCardClick,
  isMobile,
}) => {
  const [loading, setLoading] = useState(true);
  const date = useDate();
  const exercises = useExercises();

  return (
    <div className={styles.rootExercises}>
      <div className={styles.header}>
        <div className={styles.title}>
          <p>All Exercises</p>
        </div>
        <Center fontWeight="500" className={styles.date}>
          {date}
        </Center>
      </div>

      <div className={styles.container}>
        {typeof exercises === "undefined" ? (
          <Center mt="20%">
            <Spinner color="navy" size="xl" />
          </Center>
        ) : (
          <div className={styles.listContainer}>
            {exercises &&
              exercises.map((exercise) => (
                <div
                  key={exercise.id}
                  className={styles.card}
                  onClick={() => onExerciseCardClick(exercise)}
                >
                  <img
                    className={styles.imageContainer}
                    src={exercise.thumbnailURL}
                    alt={exercise.title}
                  />
                  <div className={styles.textContainer}>
                    <div className={styles.titleBar}>
                      <h3>
                        {exercise.title
                          ? exercise.title
                          : exercise.Exercise_Name}
                      </h3>
                      <div className={styles.icon}>
                        <BiDotsVerticalRounded className={styles.dots} />
                      </div>
                    </div>

                    <p>
                      {exercise.description && typeof exercise.description === "string"
                        ? exercise.description.substring(
                            0,
                            isMobile ? 80 : 150
                          )
                        : exercise.Execution && typeof exercise.Execution === "string"
                        ? exercise.Execution.substring(0, isMobile ? 80 : 150)
                        : "No description available"}
                      ...
                    </p>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminExercises;
