import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ReactGA from "react-ga";
import LoginAdmin from "./admin/Login/adminLogin";
import AdminHome from "./admin/Home/adminHome";
import { useContext, useEffect } from "react";
import ForgotPassword from "./admin/ForgotPassword/ForgotPassword";
import AdminRegistration from "./admin/Admin/adminRegistration";
import AuthContext from "./context/authContext";

ReactGA.initialize("UA-400757671");

function App() {
  const { isLoggedIn } = useContext(AuthContext);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div>
      <Router>
        <Routes>
          <Route
            path="/"
            element={!isLoggedIn ? <LoginAdmin /> : <Navigate to="/home" />}
          />
          <Route
            path="/home"
            element={isLoggedIn ? <AdminHome /> : <Navigate to="/" />}
          />
          <Route path="/resetpassword" element={<ForgotPassword />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
