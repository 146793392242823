import React, { useEffect, useState } from "react";
import useDate from "../../components/data_fetch/useDate";
import styles from "./FormContact.module.scss";
import {
  Center,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

export default function FormContact() {
  const date = useDate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showMore, setShowMore] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "contact form"));
        const fetchedData = [];
        querySnapshot.forEach((doc) => {
          fetchedData.push(doc.data());
        });
        setData(fetchedData);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  const handleToggleComment = (index) => {
    setData((prevData) => {
      const newData = [...prevData];
      newData[index].expanded = !newData[index].expanded;
      return newData;
    });
  };

  return (
    <div className={styles.rootClients}>
      <div className={styles.header}>
        <div className={styles.title}>
          <p>Form contact details</p>
        </div>
        <Center fontWeight="500" className={styles.date}>
          {date}
        </Center>
      </div>

      <div className={styles.container}>
        <Table variant="striped" className={styles.tableContainer}>
          <Thead>
            <Tr>
              <Th className={styles.row}>Name</Th>
              <Th className={styles.row}>Email</Th>
              <Th className={styles.rowComment}>Comment</Th>
            </Tr>
          </Thead>
          <Tbody>
            {loading ? (
              <Tr>
                <Td colSpan={3} textAlign="center">
                  <Spinner size="md" color="teal.500" />
                </Td>
              </Tr>
            ) : (
              data.map((item, index) => (
                <Tr key={index}>
                  <Td className={styles.row}>{item.name}</Td>
                  <Td className={styles.row}>{item.email}</Td>
                  <Td className={styles.rowComment}>
                    {!showMore ? (
                      <>
                        {item.comment.substring(0, 80)}
                        {item.comment.length > 80 && (
                          <span onClick={() => setShowMore(!showMore)}>
                            ...Show More
                          </span>
                        )}
                      </>
                    ) : (
                      <>
                        {item.comment}
                        {item.comment.length > 80 && (
                          <span onClick={() => setShowMore(!showMore)}>
                            ...Show Less
                          </span>
                        )}
                      </>
                    )}
                  </Td>
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
      </div>
    </div>
  );
}
