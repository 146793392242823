import React from "react";
import "../Clients/ClientData.scss";
import { motion } from "framer-motion";

const AdminTherapistDetails = ({ therapist, onBackToList }) => {
  return (
    <div className="container1">
      <div className="client">
        {therapist.profileImageURL !== "" ? (
          <img src={therapist.profileImageURL} alt={therapist.name} />
        ) : (
          <img src={require("../../assets/vectorProfile.png")} alt="" />
        )}
        <div className="name">{therapist.name}</div>
      </div>
      <div className="container">
        <div className="details">
          <div className="row">
            <div className="element">
              <h1>Name</h1>
              <input
                disabled={true}
                value={therapist.name}
                contentEditable="false"
              />
            </div>

            <div className="element">
              <h1>Phone No.</h1>
              <input
                disabled={true}
                value={therapist.contactNo}
                contentEditable="false"
              />
            </div>
          </div>
          <div className="row">
            <div className="element">
              <h1>Email</h1>
              <input
                disabled={true}
                value={therapist.email}
                contentEditable="false"
              />
            </div>
            <div className="element">
              <h1>Gender</h1>
              <input
                disabled={true}
                value={therapist.gender}
                contentEditable="false"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="buttons">
        <div className="btn1">
          <motion.button
            whileTap={{ scale: 0.9 }}
            whileHover={{ scale: 1.02 }}
            onClick={onBackToList}
          >
            Back
          </motion.button>
        </div>
      </div>
    </div>
  );
};

export default AdminTherapistDetails;
