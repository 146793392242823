import React, { useState } from "react";
import "./ClientData.scss";
import { doc, deleteDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { motion } from "framer-motion";
import { Button, useToast } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import ImageComponent from "../../components/ImageConponent";
function calculateAge(birthdateString) {
  // Split the birthdate string into components
  const parts = birthdateString.split("/");
  const day = parseInt(parts[0], 10);

  const month = parts[1];

  const year = parseInt(parts[2], 10);

  // Define the months in order for date calculations
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Get the current date
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Months are 0-indexed

  // Calculate the age
  let age = currentYear - year;

  // Adjust age if the birthdate for this year has not occurred yet
  if (
    currentMonth < months.indexOf(month) + 1 ||
    (currentMonth === months.indexOf(month) + 1 && day > currentDate.getDate())
  ) {
    age--;
  }

  return age;
}

const AdminClientDetails = ({ client, onBackToList }) => {
  const [showdelete, setShowDelete] = useState(false);
  const toast = useToast();
  const queryClient = useQueryClient();
  //Calculating age
  var age = calculateAge(client.userDOB);

  //Delete
  const handleDeleteClient = async () => {
    try {
      // Assuming therapist.id is the unique identifier for the therapist
      const clientDocRef = doc(db, "Users", client.id);

      // Delete the document from Firestore
      await deleteDoc(clientDocRef);

      // Handle successful deletion (e.g., show a message or navigate back)
      toast({
        title: "Success",
        description: "Client Deleted successfully.",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      queryClient.invalidateQueries(["users"]);
      onBackToList();
    } catch (error) {
      console.error("Error deleting Client: ", error);
      toast({
        title: "Error",
        description: "Error deleting Client",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      // Handle the error, show an error message, or take appropriate action
    }
  };

  return (
    <div className="container1">
      <div className="client">
        {/* <img
          className="img"
          src="https://via.placeholder.com/70x70"
          alt="img"
        /> */}
        {client && client.userProfilePhoto ? (
          <ImageComponent imagePath={client.userProfilePhoto} />
        ) : (
          <img
            src={require("../../assets/vectorProfile.png")}
            alt={client.userName}
          />
        )}
        <p className="name">{client.userName}</p>
      </div>
      <div className="container">
        <div className="details">
          <div className="row">
            <div className="element">
              <h1>Email</h1>
              <input
                disabled={true}
                value={client.userEmail}
                contentEditable="false"
              />
            </div>
            <div className="element">
              <h1>Phone No.</h1>
              <input
                disabled={true}
                value={client.phone}
                contentEditable="false"
              />
            </div>
          </div>
          <div className="row">
            <div className="element">
              <h1>Gender</h1>
              <input
                disabled={true}
                value={client.userGender}
                contentEditable="false"
              />
            </div>
            <div className="element">
              <h1>Height</h1>
              <input
                disabled={true}
                value={client.userHeightInCm}
                contentEditable="false"
              />
            </div>
          </div>

          <div className="row">
            <div className="element">
              <h1>Age</h1>
              <input disabled={true} value={age} contentEditable="false" />
            </div>
            <div className="element">
              <h1>Weight</h1>
              <input
                disabled={true}
                value={client.userWeight}
                contentEditable="false"
              />
            </div>
          </div>
          {showdelete && (
            <div className="recheckDelete">
              <div>
                <h3>Delete Client</h3>
                <hr />
                <p>Are you sure? You can't undo this action afterwards.</p>
              </div>
              <div className="buttonDelete">
                <div className="bottoncover">
                  <Button
                    as={motion.div}
                    variant="outline"
                    whileTap={{ scale: 0.9 }}
                    whileHover={{ scale: 1.01 }}
                    onClick={() => {
                      setShowDelete(false);
                    }}
                    className="btnCancel"
                  >
                    Cancel
                  </Button>
                  <Button
                    as={motion.div}
                    variant="solid"
                    whileTap={{ scale: 0.9 }}
                    whileHover={{ scale: 1.01 }}
                    onClick={handleDeleteClient}
                    className="btnDelete"
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="buttons">
        <div className="btn1">
          <motion.button
            whileTap={{ scale: 0.9 }}
            whileHover={{ scale: 1.03 }}
            onClick={onBackToList}
          >
            Go Back
          </motion.button>
        </div>
        <div className="btn2">
          <motion.button
            whileTap={{ scale: 0.8 }}
            whileHover={{ scale: 1.03 }}
            onClick={() => {
              setShowDelete(true);
            }}
          >
            Remove
          </motion.button>
        </div>
      </div>
    </div>
  );
};

export default AdminClientDetails;
