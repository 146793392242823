import classes from "./Login.module.scss";
import loginimg from "../../assets/login.png";
import { MdEmail } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
// import { AuthContext } from "../../components/data_fetch/authProvider";
import { motion } from "framer-motion";
import { Center, Spinner } from "@chakra-ui/react";
import bcrypt from "bcryptjs";
import AuthContext from "../../context/authContext";
import { RxCross2 } from "react-icons/rx";

function LoginAdmin() {
  const { login } = useContext(AuthContext);
  const [adminInfo, setAdminInfo] = useState({
    email: "",
    password: "",
  });

  const [errorMessage, setErrorMessage] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const comparePasswords = async (password, hashedPassword) => {
    try {
      const match = await bcrypt.compare(password, hashedPassword);
      return match;
    } catch (error) {
      console.error("Error comparing passwords:", error);
      throw error;
    }
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Check if the authenticated admin exists in the "Admins" collection
      const adminsRef = collection(db, "Admins");
      const q = query(adminsRef, where("email", "==", adminInfo.email));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setErrorMessage("Admin not found.");
        setLoading(false);
      } else {
        const adminData = querySnapshot.docs[0].data();
        // Compare the entered password with the password in Firebase (assuming it's plain text)
        comparePasswords(adminInfo.password, adminData.password)
          .then((match) => {
            if (match) {
              console.log("Password matches. User authenticated successfully.");
              login({ name: adminData.name, email: adminData.email });
            } else {
              console.log("Password does not match. Authentication failed.");
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
        setLoading(false);
      }
    } catch (error) {
      console.error("Error logging in admin: ", error);
      setErrorMessage("Invalid email or password.");
      setLoading(false);
    }
  };

  return (
    <div className={classes.rootLogin}>
      <div className={classes.containerMain}>
        <div className={classes.header}>
          <p>Admin Panel Login</p>
        </div>

        <div className={classes.container}>
          <div className={classes.left}>
            <div className={classes.email}>
              <div className={classes.label}>
                <p>Email</p>
              </div>
              <div className={classes.input}>
                <Center className={classes.icon}>
                  <MdEmail />
                </Center>

                <input
                  className={classes.inputEmail}
                  type="email"
                  onChange={(event) =>
                    setAdminInfo((prev) => ({
                      ...prev,
                      email: event.target.value,
                    }))
                  }
                />
                <Center className={classes.icon}></Center>
              </div>
            </div>

            <div className={classes.password}>
              <div className={classes.label}>
                <p className={classes.pass}>Password</p>
                <p className={classes.forgot}>
                  <Link to="/resetpassword">Forgot Password?</Link>
                </p>
              </div>
              <div className={classes.input}>
                <Center className={classes.icon}>
                  <RiLockPasswordFill />
                </Center>

                <input
                  className={classes.inputPassword}
                  type={showPassword ? "text" : "password"}
                  onChange={(event) =>
                    setAdminInfo((prev) => ({
                      ...prev,
                      password: event.target.value,
                    }))
                  }
                />
                <Center className={classes.icon}>
                  {showPassword ? (
                    <IoEyeOutline
                      // className={classes.EyeIcon}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  ) : (
                    <IoEyeOffOutline
                      // className={classes.EyeIcon}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  )}
                </Center>
              </div>
            </div>
            <div className={classes.buttons}>
              {loading ? (
                <div className={classes.signin}>
                  <Spinner color="white" />
                </div>
              ) : (
                <motion.div
                  whileTap={{ scale: 0.85 }}
                  whileHover={{ scale: 0.98 }}
                  className={classes.signin}
                  onClick={handleLogin}
                >
                  <p>Log In</p>
                </motion.div>
              )}
            </div>
          </div>
          <Center className={classes.right}>
            <img src={loginimg} alt="login"></img>
          </Center>
        </div>
      </div>
      {errorMessage && (
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, x: [90, 0], scale: 1 }}
          transition={{ type: "spring", duration: 0.6 }}
          className={classes.errorpopup}
        >
          <p>{errorMessage}</p>
          <div>
            <RxCross2
              color="white"
              onClick={() => {
                setErrorMessage(null);
              }}
            />
          </div>
        </motion.div>
      )}
    </div>
  );
}

export default LoginAdmin;
