import React, { useState } from "react";
import styles from "./ClientsPage.module.scss";
import DataFetcher from "../components/data_fetch/datafetcher";
import useDate from "../../components/data_fetch/useDate";
import { Center, Spinner } from "@chakra-ui/react";
import useUsers from "../components/data_fetch/useUsers";
import ImageComponent from "../../components/ImageConponent";

const AdminClientsPage = ({ onSelectClient }) => {
  const date = useDate();
  // const [clients, setClients] = useState([]);
  // const [loading, setLoading] = useState(true);
  // const handleClientsFetched = (fetchedClients) => {
  //   setClients(fetchedClients);
  //   setLoading(false);
  // };

  const clients = useUsers();

  return (
    <div className={styles.rootClients}>
      <div className={styles.header}>
        <div className={styles.title}>
          <p>Clients List</p>
        </div>
        <Center fontWeight="500" className={styles.date}>
          {date}
        </Center>

        {/* <div className={styles.buttons}>Add New</div> */}
      </div>

      <div className={styles.container}>
        {/* Render the ClientFetcher component to fetch data */}
        {/* <DataFetcher
          collectionName="Users"
          onDataFetched={handleClientsFetched}
        /> */}
        {typeof clients === "undefined" ? (
          <Center mt="20%">
            <Spinner color="navy" size="xl" />
          </Center>
        ) : (
          <div className={styles.listContainer}>
            {clients &&
              clients.map((client) => (
                <div className={styles.card} key={client.userID}>
                  <div className={styles.imageContainer}>
                    {client && client.userProfilePhoto ? (
                      <ImageComponent imagePath={client.userProfilePhoto} />
                    ) : (
                      <img
                        src={require("../../assets/vectorProfile.png")}
                        alt={client.userName}
                      />
                    )}
                  </div>

                  <div className={styles.textContainer}>
                    <div className={styles.username}>
                      <p>{client.userName}</p>
                    </div>
                    <div className={styles.viewbox}>
                      <div
                        className={styles.buttons}
                        onClick={() => onSelectClient(client)}
                      >
                        <p>View</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminClientsPage;
