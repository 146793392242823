import React, { useState } from "react";
import { db } from "../../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  setDoc,
} from "firebase/firestore";
import classes from "./Settings.module.scss";
import { Center, Spinner, useToast } from "@chakra-ui/react";
import useDate from "../../components/data_fetch/useDate";
import bcrypt from "bcryptjs";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";

function Settings() {
  const date = useDate();
  const [adminInfo, setAdminInfo] = useState({
    email: "", // Add an email field
    currentPassword: "",
    newPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const hashPassword = async (password) => {
    try {
      const salt = await bcrypt.genSalt(10); // Generate a salt with 10 rounds
      const hash = await bcrypt.hash(password, salt); // Hash the password with the generated salt
      return hash;
    } catch (error) {
      console.error("Error hashing password:", error);
      throw error;
    }
  };
  const comparePasswords = async (password, hashedPassword) => {
    try {
      const match = await bcrypt.compare(password, hashedPassword);
      return match;
    } catch (error) {
      console.error("Error comparing passwords:", error);
      throw error;
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAdminInfo({ ...adminInfo, [name]: value });
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (
        adminInfo.email === "" ||
        adminInfo.newPassword === "" ||
        adminInfo.currentPassword === ""
      ) {
        throw new Error("Some fields are empry");
      }
      // Check if the authenticated admin exists in the "Admins" collection
      const adminsRef = collection(db, "Admins");
      const q = query(adminsRef, where("email", "==", adminInfo.email));
      const querySnapshot = await getDocs(q);
      const hashedPassword = await hashPassword(adminInfo.newPassword);
      if (querySnapshot.empty) {
        throw new Error("Admin not found.");
      } else {
        const adminData = querySnapshot.docs[0].data();
        const passwordInFirebase = adminData.password;
        // Compare the current password with the one stored in Firebase
        comparePasswords(adminInfo.currentPassword, passwordInFirebase)
          .then(async (match) => {
            if (match) {
              // Update the password
              const adminDocRef = doc(db, "Admins", querySnapshot.docs[0].id);
              try {
                await setDoc(
                  adminDocRef,
                  { password: hashedPassword },
                  { merge: true }
                );
              } catch {
                throw new Error("Something went wrong!");
              }
              setAdminInfo({ email: "", currentPassword: "", newPassword: "" });
              toast({
                title: "Success",
                description: "Password updated successfully.",
                status: "success",
                duration: 3000,
                isClosable: true,
              });
            } else {
              throw new Error("Wrong current password.");
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
        setLoading(false);
      }
    } catch (error) {
      toast({
        title: "Error!",
        description: `${error}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  return (
    <div className={classes.settings}>
      <div className={classes.header}>
        <p>Update Password</p>
        <Center fontWeight="500" className={classes.date}>
          {date}
        </Center>
      </div>

      <div className={classes.container}>
        <form onSubmit={handleUpdatePassword}>
          <div className={classes.field}>
            <label className={classes.fieldName}>Email</label>
            <div className={classes.fieldInput}>
              <input
                type="email"
                name="email"
                value={adminInfo.email}
                onChange={handleInputChange}
                className={classes.Input}
              />
            </div>
          </div>
          <div className={classes.field}>
            <label className={classes.fieldName}>Current Password</label>
            <div className={classes.fieldInput}>
              <input
                type={showCurrentPassword ? "text" : "password"}
                name="currentPassword"
                value={adminInfo.currentPassword}
                onChange={handleInputChange}
                className={classes.Input}
              />
              <Center className={classes.icon}>
                {showCurrentPassword ? (
                  <IoEyeOutline
                    // className={classes.EyeIcon}
                    onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                  />
                ) : (
                  <IoEyeOffOutline
                    // className={classes.EyeIcon}
                    onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                  />
                )}
              </Center>
            </div>
          </div>

          <div className={classes.field}>
            <label className={classes.fieldName}>New Password</label>
            <div className={classes.fieldInput}>
              <input
                type={showPassword ? "text" : "password"}
                name="newPassword"
                value={adminInfo.newPassword}
                onChange={handleInputChange}
                className={classes.Input}
              />
              <Center className={classes.icon}>
                {showPassword ? (
                  <IoEyeOutline
                    // className={classes.EyeIcon}
                    onClick={() => setShowPassword(!showPassword)}
                  />
                ) : (
                  <IoEyeOffOutline
                    // className={classes.EyeIcon}
                    onClick={() => setShowPassword(!showPassword)}
                  />
                )}
              </Center>
            </div>
          </div>
          {loading ? (
            <Center className={classes.button}>
              <Spinner color="white" />
            </Center>
          ) : (
            <button type="submit" className={classes.button}>
              Update
            </button>
          )}
        </form>
      </div>
    </div>
  );
}

export default Settings;
