import classes from "./Home.module.scss";
import Header from "../../components/header";
import Profile from "../components/adminProfile";
import AdminSidebar from "../components/adminSidebar";
import { MdLogout } from "react-icons/md";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MainAdminExercises from "../Exercises/mainAdminExercises";
import MainAdminClients from "../Clients/mainAdminClients";
import MainAdminTherapists from "../Therapists/mainAdminTherapists";
import AdminDashboard from "./adminDashboard";
import AdminRegistration from "../Admin/adminRegistration";
import Settings from "../Settings/settings";
import { signOut } from "@firebase/auth";
import { auth } from "../../firebase";
// import {
//   AuthContext,
//   AuthProvider,
// } from "../../components/data_fetch/authProvider";
import { VStack } from "@chakra-ui/react";
import FormContact from "../Form Contact/FormContact";
import AuthContext, { AuthProvider } from "../../context/authContext";

function AdminHome(props) {
  //For signout option
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 820);
  const [isOpen, setIsOpen] = useState(false);
  const [show, setShow] = useState(true);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };
  const [selectedOption, setSelectedOption] = useState(0);
  // Map of options to their corresponding components
  const componentMap = {
    0: <AdminDashboard isMobile={isMobile} />,
    1: <MainAdminClients />,
    2: <MainAdminTherapists />,
    3: <MainAdminExercises isMobile={isMobile} />,
    4: <FormContact />,
    5: <Settings />,
    6: <AdminRegistration />,
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 820);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleOptionClick = (index) => {
    setSelectedOption(index);
    setIsOpen(false);
  };

  return (
    <div>
      <div className={classes.root}>
        {isOpen && (
          <div className={classes.overlay} onClick={toggleDrawer}></div>
        )}
        <div
          className={`${classes.left} ${isMobile && classes.drawer} ${
            isOpen ? classes.open : ""
          }`}
        >
          <div className={classes.profile}>
            <AuthProvider>
              <Profile />
            </AuthProvider>
          </div>

          <div className={classes.sideBar}>
            <AdminSidebar handleOptionClick={handleOptionClick} />
          </div>

          <div className={classes.logout}>
            <div className={classes.button} onClick={() => logout()}>
              <div className={classes.icon}>
                <MdLogout color="#0D30AC" />
              </div>
              <div className={classes.option}>Log Out</div>
            </div>
          </div>
        </div>

        <div className={`${classes.right} ${isMobile ? classes.right1 : ""}`}>
          {isMobile && <Header toggleDrawer={toggleDrawer} />}
          <div className={classes.bottom}>
            {selectedOption !== null && componentMap[selectedOption]}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminHome;
