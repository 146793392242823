import { useContext, useState } from "react";
import classes from "../../styles/Profile.module.scss";
import { IoCameraOutline } from "react-icons/io5";
import AuthContext from "../../context/authContext";
import useImageUrl from "./data_fetch/useImageUrl";
import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { Button, Center, Flex, useToast } from "@chakra-ui/react";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { app } from "../../firebase";
import { useQueryClient } from "@tanstack/react-query";
import { RxCross2 } from "react-icons/rx";
import { Progress } from "@chakra-ui/react";

const Profile = () => {
  const [file, setFile] = useState(null);
  const { user } = useContext(AuthContext);
  const toast = useToast();
  const imageUrl = useImageUrl();
  const queryClient = useQueryClient();
  const [progressValue, setProgressValue] = useState(null);
  const [edit, setEdit] = useState("");
  console.log(file);
  // console.log(file);

  const downloadProfileImage = async (downloadURL) => {
    const adminsRef = collection(db, "Admins");
    const q = query(adminsRef, where("email", "==", JSON.parse(user).email));
    const querySnapshot = await getDocs(q);
    const adminDocRef = doc(db, "Admins", querySnapshot.docs[0].id);
    await setDoc(adminDocRef, { profileImage: downloadURL }, { merge: true });
  };

  const updateImageUrl = async () => {
    setEdit("progress");
    try {
      const filename = "admin/" + Date.now() + file.name;
      const storage = getStorage(app);
      const storageRef = ref(storage, filename);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgressValue(progress);
        },
        (error) => {
          throw new Error("Upload failed");
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref)
            .then((downloadURL) => {
              downloadProfileImage(downloadURL); // Uncomment and define this function if needed
            })
            .then((data) => {
              queryClient.invalidateQueries(["adminimage"]);
              toast({
                title: "Success",
                description: `Image updated Successfully`,
                status: "success",
                duration: 3000,
                isClosable: true,
              });
              setEdit("");
              setProgressValue(null);
            })
            .catch((error) => {
              throw new Error("Upload failed");
            });
        }
      );
    } catch (err) {
      toast({
        title: "Error",
        description: err.message,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <div className={classes.rootProfile}>
      <div className={classes.userPicture}>
        <div>
          <label htmlFor="image">
            <div>
              <IoCameraOutline color="navy" className={classes.cameraIcon} />
              <img
                style={{ cursor: "pointer" }}
                className={classes.profileimage}
                src={
                  file && edit !== ""
                    ? URL.createObjectURL(file)
                    : imageUrl
                    ? imageUrl
                    : require("../../assets/vectorProfile.png")
                }
                alt=""
              />
            </div>
          </label>
          <input
            type="file"
            name=""
            style={{ display: "none" }}
            id="image"
            onChange={(e) => {
              setFile(e.target.files[0]);
              setEdit("edit");
            }}
          />
        </div>
      </div>
      {edit === "edit" && !progressValue && (
        <Flex flexDir="column" gap="5px" mt="4%">
          <Center
            onClick={() => {
              setFile("");
              setEdit("");
            }}
            cursor="pointer"
          >
            <RxCross2 size={25} color="navy" />
          </Center>
          <Button
            backgroundColor="#0d30ac"
            color="white"
            size="sm"
            _hover={{ backgroundColor: "navy" }}
            onClick={updateImageUrl}
          >
            Confirm
          </Button>
        </Flex>
      )}
      {edit === "progress" && (
        <Progress
          value={parseInt(progressValue)}
          borderRadius="10px"
          hasStripe
          w="100%"
          mt="8%"
          colorScheme="blue"
        />
      )}
      {edit === "" && <p className={classes.name}>Admin</p>}
    </div>
  );
};

export default Profile;
