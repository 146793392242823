import classes from "../../styles/SideBar.module.scss";
import { MdSpaceDashboard } from "react-icons/md";
import { IoMdAnalytics } from "react-icons/io";
import { IoMdSettings } from "react-icons/io";
import { BiRun } from "react-icons/bi";
import { BsFillPersonFill } from "react-icons/bs";
import { IoInformationCircleOutline } from "react-icons/io5";
import { FaUserGroup } from "react-icons/fa6";

import { useEffect, useState } from "react";
import Profile from "./adminProfile";

const AdminSidebar = ({ handleOptionClick }) => {
  const [selectedOption, setSelectedOption] = useState(0);

  const handleItemClick = (index) => {
    setSelectedOption(index);
    handleOptionClick(index); // Notify the Home component of the selected option
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 430);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 430);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //Array of items
  const sidebarItems = [
    {
      icon: (
        <MdSpaceDashboard color={selectedOption === 0 ? "#0D30AC" : "white"} />
      ),
      text: "Dashboard",
    },
    {
      icon: <FaUserGroup color={selectedOption === 1 ? "#0D30AC" : "white"} />,
      text: "Clients",
    },
    {
      icon: <FaUserGroup color={selectedOption === 2 ? "#0D30AC" : "white"} />,
      text: "Trainers",
    },
    {
      icon: <BiRun color={selectedOption === 3 ? "#0D30AC" : "white"} />,
      text: "Exercises",
    },
    {
      icon: (
        <IoInformationCircleOutline
          color={selectedOption === 4 ? "#0D30AC" : "white"}
        />
      ),
      text: "Form contact",
    },
    {
      icon: <IoMdSettings color={selectedOption === 5 ? "#0D30AC" : "white"} />,
      text: "Settings",
    },
    {
      icon: (
        <BsFillPersonFill color={selectedOption === 6 ? "#0D30AC" : "white"} />
      ),
      text: "Admin",
    },
  ];

  return (
    <div className={classes.rootSidebar}>
      <div className={classes.sidebar}>
        {sidebarItems.map((item, index) => (
          <a
            href={item.link}
            key={index}
            onClick={() => handleItemClick(index)}
            className={index === selectedOption ? classes.selectedOption : ""}
          >
            <div className={classes.icon}>{item.icon}</div>
            <div className={classes.option}>
              <span>{item.text}</span>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default AdminSidebar;
