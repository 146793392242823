import React, { useState } from "react";
import { motion } from "framer-motion";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
} from "@chakra-ui/react";
import { doc, deleteDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import styles from "./adminTherapistEdit.module.scss";
import { useQueryClient } from "@tanstack/react-query";

export default function AdminTherapistEdit({ props }) {
  const [showdelete, setShowDelete] = useState(false);
  const [showblock, setShowBlock] = useState(false);
  // const [therapist, setTherapist] = useState(props);
  const toast = useToast();
  const queryClient = useQueryClient();
  const handleDeleteTherapist = async () => {
    try {
      // Assuming therapist.id is the unique identifier for the therapist
      const therapistDocRef = doc(db, "physiotherapist", props.id);

      // Delete the document from Firestore
      try {
        await deleteDoc(therapistDocRef);
        await fetch(
          "https://us-central1-physiotherapistadmin.cloudfunctions.net/deleteUser",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json", // Corrected header name
            },
            body: JSON.stringify({ uid: props.physiotherapistId }), // Serialize the body to JSON
          }
        );
        toast({
          title: "Success",
          description: `User Deleted successfully`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch {
        throw new Error("Something went wrong, User not deleted");
      }
      setShowDelete(false);
      queryClient.invalidateQueries(["trainers"]);
    } catch (error) {
      toast({
        title: "Error!",
        description: `${error.message}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleBlockTherapist = async () => {
    try {
      const docId = props.id;
      const userDataRef = doc(db, "physiotherapist", docId);
      try {
        await updateDoc(userDataRef, {
          accountStatus: !props.accountStatus,
        });
        toast({
          title: "Success",
          description: props.accountStatus
            ? `User blocked successfully`
            : "User unblocked successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch {
        throw new Error("Something went wrong!");
      }
      setShowBlock(false);
      queryClient.invalidateQueries(["trainers"]);
    } catch (error) {
      toast({
        title: "Error!",
        description: `${error.message}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  return (
    <div>
      <div className={styles.icon}>
        <Menu isLazy={true} placement="left">
          <MenuButton
            className={styles.threedot}
            borderWidth="0px"
            backgroundColor="transparent"
            cursor={"pointer"}
            _hover={{ backgroundColor: "transparent" }}
          >
            <BsThreeDotsVertical />
          </MenuButton>
          <MenuList className={styles.menu}>
            <MenuItem
              className={styles.list}
              onClick={() => {
                setShowDelete(true);
                setShowBlock(false);
              }}
            >
              Delete User
            </MenuItem>
            {props.accountStatus ? (
              <MenuItem
                className={styles.list}
                onClick={() => {
                  setShowBlock(true);
                  setShowDelete(false);
                }}
              >
                Block User
              </MenuItem>
            ) : (
              <MenuItem
                className={styles.list}
                onClick={() => {
                  setShowBlock(true);
                }}
              >
                Unblock User
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      </div>
      {showdelete && (
        <div className={styles.recheckDelete}>
          <div>
            <h3>Delete User</h3>
            <hr></hr>
            <p>Are you sure? You can't undo this action afterwards.</p>
          </div>
          <div className={styles.buttonDelete}>
            <div className={styles.bottoncover}>
              <motion.button
                whileTap={{ scale: 0.9 }}
                whileHover={{ scale: 1.05 }}
                onClick={() => {
                  setShowDelete(false);
                }}
                className={styles.btnCancel}
              >
                Cancel
              </motion.button>
              <motion.button
                whileTap={{ scale: 0.9 }}
                whileHover={{ scale: 1.05 }}
                onClick={handleDeleteTherapist}
                className={styles.btnDelete}
              >
                Delete
              </motion.button>
            </div>
          </div>
        </div>
      )}
      {showblock && (
        <div className={styles.recheckDelete}>
          <div>
            {props.accountStatus ? <h3>Block User</h3> : <h3>Unblock User</h3>}
            <hr></hr>

            <p>Are you sure?</p>
          </div>
          <div className={styles.buttonDelete}>
            <div className={styles.bottoncover}>
              <motion.button
                whileTap={{ scale: 0.9 }}
                whileHover={{ scale: 1.05 }}
                onClick={() => {
                  setShowBlock(false);
                }}
                className={styles.btnCancel}
              >
                Cancel
              </motion.button>
              <motion.button
                whileTap={{ scale: 0.9 }}
                whileHover={{ scale: 1.05 }}
                onClick={handleBlockTherapist}
                className={styles.btnDelete}
              >
                {props.accountStatus ? "Block" : "Unblock"}
              </motion.button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
