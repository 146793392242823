import React, { createContext, useState, useEffect } from "react";

export const AuthContext = createContext({
  isLoggedIn: false,
  user: null,
  login: (userData) => {},
  logout: () => {},
});

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    // Check for existing user data in local storage on initial render
    const userStr = localStorage.getItem("user");
    if (userStr) {
      try {
        const user = JSON.parse(userStr);
        return true; // User is logged in
      } catch (error) {
        console.error("Error parsing user data from local storage:", error);
        localStorage.removeItem("user"); // Remove invalid data
        return false;
      }
    }
    return false; // No user in local storage
  });
  const [user, setUser] = useState(() => localStorage.getItem("user"));

  // Handle login logic (replace with your authentication mechanism)
  const login = (userData) => {
    setIsLoggedIn(true);
    setUser(userData);
    localStorage.setItem("user", JSON.stringify(userData));
  };

  // Handle logout logic
  const logout = () => {
    setIsLoggedIn(false);
    setUser(null);
    localStorage.removeItem("user");
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
